<template>
    <upload-input
        v-bind="upload_input_props"
        :delete-button-title="delete_button_title"
        :replace-title="replace_title"
        v-on="$listeners"
    >
        <template v-slot:dragging-title>
            <slot name="dragging-title">{{ $t("Drop your image to upload it") }}</slot>
        </template>
        <template v-slot:empty-title>
            <slot name="empty-title">{{ $t("Drag your image here, or use the button below") }}</slot>
        </template>
        <template v-slot:empty-button-title>
            <slot name="empty-button-title">{{ $t("Select image...") }}</slot>
        </template>
        <template v-slot:instructions>
            <slot name="instructions"></slot>
        </template>
        <template v-slot:display>
            <div
                v-if="url === 'TOOBIG'"
                class="empty"
            >
                <div>
                    <app-icon glyph="image" size="4x" />
                    <p class="text-small empty-subtitle">{{ $t("Image too big for preview. It will be displayed once save.") }}</p>
                </div>
            </div>
            <responsive-image
                :src="url"
                :alt="alt"
            />
        </template>
    </upload-input>
</template>

<script>
import UploadInput from "./UploadInput"
import ResponsiveImage from "@/nibnut/components/Media/ResponsiveImage"

export default {
    components: {
        UploadInput,
        ResponsiveImage
    },
    computed: {
        upload_input_props () {
            const { alt, deleteButtonTitle, replaceTitle, ...upload_input_props } = this.$props
            return upload_input_props
        },
        delete_button_title () {
            return this.deleteButtonTitle || this.$t("Delete image...")
        },
        replace_title () {
            return this.replaceTitle || this.$t("Replace image...")
        }
    },
    props: {
        ...UploadInput.props,
        alt: {
            type: String,
            required: true
        }
    }
}
</script>
