<template>
    <base-select
        ref="field"
        :id="id"
        :name="name"
        :id-field="idField"
        :label-field="labelField"
        :options="options"
        :glyph="glyph"
        :expand-on-focus="expandOnFocus"
        :multiple="true"
        :disabled="disabled || is_full"
        :required="required"
        @search="search"
        @input="pick"
    >
        <template v-slot:selection>
            <slot name="selection">
                <div
                    v-for="option in value"
                    :key="option[idField]"
                    class="chip"
                >
                    {{ option[labelField] }}
                    <a
                        href
                        class="btn btn-clear"
                        aria-label="Close"
                        role="button"
                        @click.prevent="unpick(option)"
                    ></a>
                </div>
            </slot>
        </template>
    </base-select>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import BaseSelect from "./BaseSelect"

export default {
    name: "BaseTagInput",
    mixins: [is_nibnut_component],
    components: {
        BaseSelect
    },
    methods: {
        search (query) {
            this.$emit("search", query)
        },
        pick (value, field, option) {
            if(!this.is_full) {
                if(!!this.maxPicks && !!value && (value >= this.maxPicks) && !!document.activeElement && !!document.activeElement.blur) document.activeElement.blur()
                this.$emit("input", value, field, option)
            }
        },
        unpick (option) {
            this.$emit("unpick", option[this.idField], this.name, option)
        }
    },
    computed: {
        is_full () {
            return !!this.maxPicks && !!this.value && (this.value.length >= this.maxPicks)
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        glyph: {
            type: String,
            default: "more-vertical"
        },
        expandOnFocus: {
            type: Boolean,
            default: true
        },
        maxPicks: {
            type: Number,
            default: 0
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
