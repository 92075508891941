<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    {{ display_value }}
                </label>
            </slot>
        </template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <input-group>
            <base-dropdown
                ref="field"
                :id="identifier"
                :name="name"
                :value="value"
                :size="size"
                :idField="idField"
                :labelField="labelField"
                :disabledField="disabledField"
                :enabledField="enabledField"
                :emptyValue="emptyValue"
                :emptyLabel="emptyLabel"
                :options="options"
                :disabled="!editable || disabled"
                :required="required"
                @change="changed"
            />
        </input-group>
    </form-group>
</template>

<script>
import is_nibnut_component from "../../mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import InputGroup from "./InputGroup"
import BaseDropdown from "./BaseDropdown"

export default {
    name: "FormDropdown",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        InputGroup,
        BaseDropdown
    },
    mounted () {
        this.update_display_value()
    },
    watch: {
        value: "update_display_value"
    },
    methods: {
        changed (event) {
            if(this.editable) {
                const option = ((event.target.value === this.emptyValue) || (this.emptyValue === parseInt(event.target.value))) ? { [this.idField]: this.emptyValue, [this.labelField]: this.emptyLabel } : this.options.find(option => (option[this.idField] === event.target.value) || (option[this.idField] === parseInt(event.target.value)))
                this.$emit("input", option[this.idField], event.target.name, option)
            }
        },
        update_display_value () {
            this.display_value = ""
            if(this.value !== this.emptyValue) {
                const chosen_option = this.options.find(option => option.id === this.value)
                this.display_value = chosen_option ? chosen_option[this.labelField] : ""
            }
        }
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        disabledField: {
            type: String,
            default: "" // empty = no disabling
        },
        enabledField: {
            type: String,
            default: "" // empty = no enabling
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            display_value: ""
        }
    }
}
</script>
