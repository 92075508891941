import profile_utilities from "./ProfileUtilities"
import ui_utilities from "./UiUtilities"

const handles_choices = {
    mixins: [profile_utilities, ui_utilities],
    mounted () {
        this.set_data_source()
    },
    watch: {
        profile_id: "maybe_load_option",
        value: "maybe_load_option",
        dataSource: "set_data_source"
    },
    methods: {
        load_options () {
            if(this.is_remote_source && (this.value || this.showAll || (this.option_query.length >= this.minOptionQueryLength))) {
                this.loading_options = true
                return this.$store.dispatch("AUTOSUGGEST", {
                    entity: this.dataSource,
                    context: this.name,
                    passthru: !this.storedDataSource,
                    data: {
                        value: this.value,
                        query: this.showAll ? "" : this.option_query,
                        context_id: this.contextId,
                        ...this.dataSourceAdditionalData
                    } // value is either one id or an array of ids
                }).then(options => {
                    if(this.storedDataSource) this.options = (!!options && !!options.length) ? this.entity_records(this.dataSource, options) : []
                    else this.options = options
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading_options = false
                })
            }
            return Promise.resolve()
        },
        maybe_load_option () {
            if((!!this.profile_id || !!this.is_public_page) && !!this.dataSource && !!this.value) this.load_options()
        },
        set_data_source () {
            if(this.is_remote_source) this.maybe_load_option()
            else this.options = this.dataSource
        },
        search (query) {
            this.option_query = query
            if(this.option_query) {
                const existing_option = this.options.find(option => {
                    return `${option[this.labelField].toLowerCase()}` === this.option_query.toLowerCase()
                })
                if(existing_option) this.autopick(existing_option)
            }
            if(this.is_remote_source) this.load_options()
            else this.$emit("search", query)
        },
        autopick (option) {
            console.error("HandlesChoices needs to define autopick method")
        }
    },
    computed: {
        is_remote_source () {
            return !!this.dataSource && (typeof this.dataSource === "string")
        },
        available_options () {
            // ALL the options available for picking ; usually only filters out the selected values, nothing else
            return this.options
        },
        filtered_options () {
            const options = (this.showAll && !this.option_query)
                ? this.available_options
                : this.available_options.filter(option => {
                    return option[this.labelField].toLowerCase().indexOf(this.option_query.toLowerCase()) >= 0
                })

            if(this.adHoc && !!this.option_query) {
                const existing_option = this.options.find(option => {
                    return `${option[this.labelField].toLowerCase()}` === this.option_query.toLowerCase()
                })
                if(!existing_option) {
                    const option = this.options.find(option => option[this.idField] === this.emptyValue)
                    if(option) option[this.labelField] = `${this.adHocChoicePrefix}${this.option_query}`
                    else options.push({ [this.idField]: this.emptyValue, [this.labelField]: `${this.adHocChoicePrefix}${this.option_query}` })
                }
            }

            return options
        }
    },
    props: {
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        dataSource: { // either an array of options (locally sourced) OR an entity name (remote source ; api endpoint /<entity>/autosuggest/<name>)
            validator: prop => !!prop && (Array.isArray(prop) || (typeof prop === "string")),
            required: true,
            default () {
                return []
            }
        },
        dataSourceAdditionalData: {
            type: Object,
            default () {
                return { fields: ["id", "name"] }
            }
        },
        storedDataSource: {
            type: Boolean,
            default: false
        },
        contextId: {
            default: null
        },
        adHoc: {
            type: Boolean,
            default: false
        },
        adHocChoicePrefix: {
            type: String,
            default: ""
        },
        showAll: {
            type: Boolean,
            default: true
        },
        minOptionQueryLength: {
            type: Number,
            default: 3
        }
    },
    data () {
        return {
            options: [],
            option_query: "",
            loading_options: false
        }
    }
}

export default handles_choices
