import Vue from "vue"
import VueCookie from "vue-cookie"
import VueMq from "vue-mq"
import VueAnimXYZ from "@animxyz/vue"
import VueClipboard from "vue-clipboard2"
import Vue2TouchEvents from "vue2-touch-events"
import VueI18n from "vue-i18n"
import dayjs from "dayjs"
import accounting from "accounting"

import "./app-sw"
import router from "./router"
import store from "./store"

import App from "./App.vue"

/*
 * TOP OF MIND:
 * https://alligator.io/vuejs/vue-async-components-load-error/
 * https://vuejsdevelopers.com/2018/08/27/vue-js-form-handling-vuelidate/
 */

Vue.use(VueCookie)
Vue.use(VueMq, {
    breakpoints: {
        xs: 481,
        sm: 551,
        md: 841,
        lg: 961,
        tl: 1025, // tablet landscape
        xl: 1281,
        xxl: 1441
    }
})
Vue.use(VueAnimXYZ)
Vue.use(VueClipboard)
Vue.use(Vue2TouchEvents)
Vue.use(VueI18n)

/*
dayjs.updateLocale("en", {
    longDateFormat : {
        LT: "h:mm A",
        LTS: "h:mm:ss A",
        L: "MM/DD/YYYY",
        LL: "MMMM Do", // year added only if needed
        LLL: "MMMM Do, YYYY",
        LLLL: "dddd, MMMM Do, YYYY"
    }
})
dayjs.updateLocale("fr", {
    longDateFormat : {
        LT: "H:mm",
        LTS: "H:mm:ss",
        L: "DD/MM/YYYY",
        LL: "Do MMMM", // year added only if needed
        LLL: "Do MMMM YYYY",
        LLLL: "dddd le Do MMMM YYYY"
    }
})
*/
// moment.locale("en")
Vue.prototype.$dayjs = dayjs

/*
accounting.settings = {
    currency: {
        symbol : "$",   // default currency symbol is '$'
        format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
        decimal : ".",  // decimal point separator
        thousand: ",",  // thousands separator
        precision : 2   // decimal places
    },
    number: {
        precision : 0,  // default precision on numbers is 0
        thousand: ",",
        decimal : "."
    }
}
*/
Vue.prototype.$accounting = accounting

const notifies = {
    methods: {
        $notify (message, type = "primary", dismiss_after = 7000, message_id = null) {
            this.$store.dispatch("SYSTEM_MESSAGE", {
                type,
                message,
                dismiss_after,
                message_id
            })
        },
        $error (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "error", dismiss_after, message_id)
        },
        $warn (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "warning", dismiss_after, message_id)
        },
        $success (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "success", dismiss_after, message_id)
        }
    }
}
Vue.mixin(notifies)

// === FILTERS: v-bind="'I\'m good' | nibnut.slugify" OR {{ 'I\'m good' | nibnut.slugify }} OR this.nibnut_filter("nibnut.slugify", ["I'm good"])
const standardized_date = (date) => {
    return dayjs(date)
}
const filters = {
    "nibnut.slugify": (value, replacement = "-") => {
        if(!value) return value
        return value.toLowerCase().replace(/[^a-zA-Z\-0-9]/g, replacement)
    },
    /*
    dayjs.extend(relativeTime)
    "nibnut.date_from_now": (date) => {
        return standardized_date(date).fromNow()
    },
    */
    "nibnut.date": (date, date_format = "YYYY-MM-DD") => { // YYYY-MM-DD HH:mm:ss
        if(!date) return ""
        return standardized_date(date).format(date_format)
    },
    "nibnut.number": (number, number_format = "0,0.0", default_value = null) => {
        if(!number && default_value) return default_value
        const precision = number_format.replace(/[0,]+?(?:\.(0+))?$/, "$1").length
        return accounting.formatNumber(number, precision)
    },
    "nibnut.currency": (number, number_format = "0,0.00", default_value = null) => {
        if(!number && default_value) return default_value
        return accounting.formatMoney(number)
    },
    "nibnut.number-field": (number, editable, number_format, default_value) => {
        if(editable) return number
        return filters["nibnut.number"].apply(this, [number, number_format, default_value])
    },
    "nibnut.phone": (phone) => {
        if(!phone) return ""
        const matches = phone.match(/^(\d{3})(\d{3})(\d{4})$/)
        if(matches) return `(${matches[1]}) ${matches[2]}-${matches[3]}`
        return ""
    },
    "nibnut.mailto": (email) => {
        if(!email) return ""
        return "mailto:" + email
    },
    "nibnut.telto": (phone) => {
        if(!phone) return ""
        return "tel:" + phone.replace(/[^+0-9p]+/g, "")
    },
    "nibnut.ucwords": (text) => {
        if(!text) return text
        return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter) => {
            return letter.toUpperCase()
        })
    },
    "nibnut.truncate": (text, max_length, ellipsis = "...") => {
        const length = text.length
        if(length <= max_length) return text
        return text.substring(0, max_length - ellipsis.length) + ellipsis
    }
}
Vue.mixin({
    methods: {
        nibnut_filter (filter_name, filter_arguments) {
            return filters[filter_name].apply(this, filter_arguments)
        }
    }
})
Object.keys(filters).forEach(filter => {
    Vue.filter(filter, filters[filter])
})

// === DIRECTIVES
Vue.directive("nibnut-on-scroll", {
    inserted: (element, binding) => {
        const listener = (event) => {
            if(binding && !!binding.value && binding.value(event, element)) window.removeEventListener("scroll", listener)
        }
        window.addEventListener("scroll", listener)
    }
})

if(process.env.NODE_ENV === "development") {
    Vue.config.debug = true
    Vue.config.devtools = true
    Vue.config.performance = true
}

const i18n = new VueI18n({
    locale: "en",
    runtimeOnly: false
})

window.nibnut_app = new Vue({
    el: "#app",
    store,
    router,
    i18n,
    render: h => h(App)
})
