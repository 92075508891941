<template>
    <div
        v-if="!!message && !!message.message"
        :class="{ [`toast-${message.type}`]: true }"
        class="toast system-message"
        @click="$emit('click')"
    >
        <default-button
            :label="$t('Dismiss')"
            color="clear"
        >
        </default-button>
        <div>
            <div>{{ message.message }}</div>
        </div>
        <progress
            v-if="!!countdown"
            :value="countdown"
            :max="message.dismiss_after"
            class="progress"
        ></progress>
        <label>
            {{ $tc("Auto-dismissed in one second | Auto-dismissed in {n} seconds", seconds, { n: seconds }) }}
        </label>
    </div>
</template>

<script>
import { DefaultButton } from "@/nibnut/components"

export default {
    components: {
        DefaultButton
    },
    computed: {
        seconds () {
            return Math.round(this.countdown / 1000) + 1
        }
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        countdown: {
            validator: prop => !prop || (typeof prop === "number")
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

.toast.system-message {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: $top-nav-height;
    z-index: $zindex-system-messages;

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        overflow-y: auto;
    }

    progress,
    label {
        position: absolute;
        color: $light-color;
        opacity: 0.5;
    }
    progress {
        left: -1px;
        right: -1px;
        bottom: -1px;
        width: auto;

        &::-webkit-progress-bar {
            background: transparent;
        }
        &::-webkit-progress-value {
            background: $light-color;
        }
        &::-moz-progress-bar {
            background: $light-color;
        }
    }
    label {
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.5rem;
    }
    .btn-clear {
        position: absolute;
        top: 50%;
        right: $control-padding-x-lg;
        transform: translate(0, -50%);
    }

    &.toast-primary progress {
        background: $primary-color;
    }
    &.toast-success progress {
        background: $success-color;
    }
    &.toast-warning progress {
        background: $warning-color;
    }
    &.toast-error progress {
        background: $error-color;
    }
}
</style>
