<template>
    <div class="empty nibnut-offline">
        <div class="empty-icon">
            <app-icon glyph="mountain" size="5x" />
        </div>
        <p class="empty-title h5">{{ $t("Off the beaten path, are we?") }}</p>
        <p class="empty-subtitle">{{ $t("You are currently offline - good for you! Your app will be right back as soon as it can grab a network...") }}</p>
    </div>
</template>

<script>
import AppIcon from "@/nibnut/components/AppIcon"

export default {
    name: "Offline",
    components: {
        AppIcon
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-offline {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $zindex-offline;
}
</style>
