import ui_utilities from "./UiUtilities"

const handles_errors = {
    mixins: [ui_utilities],
    methods: {
        clear_all_errors () {
            this.errors = {}
            this.feedback_status = null
            this.feedback_message = ""
        },
        clear_error (error_id) {
            if(this.errors[error_id]) this.$delete(this.errors, error_id)
        },
        add_error (error_id, message, scroll = true) {
            if(!message) this.clear_error(error_id)
            else this.$set(this.errors, error_id, message)
            if(scroll) this.scroll_to(document.getElementById(error_id))
        },
        has_error (error_id) {
            if(error_id) return this.errors[error_id]
            return !!Object.keys(this.errors).length
        },
        receive_error (error) {
            if(error.topic_id && (!Array.isArray(error.topic_id) || !!error.topic_id.length) && !!document.getElementById(Array.isArray(error.topic_id) ? error.topic_id[0] : error.topic_id)) {
                if(this.handles_feedback) this.feedback_status = false
                this.add_error(error.topic_id, error.message)
            } else this.topic_less_error(error)
        },
        topic_less_error (error) {
            if(this.handles_feedback) {
                this.feedback_status = false
                this.feedback_message = error.message
            } else this.$error(error.message)
        }
    },
    data () {
        return {
            errors: {},
            handles_feedback: false,
            feedback_status: null,
            feedback_message: ""
        }
    }
}

export default handles_errors
