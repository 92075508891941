<template>
    <form-group
        v-bind="form_group_props"
        class="form-options-picker"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    {{ display_value }}
                </label>
            </slot>
        </template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint>
            <div class="columns text-small">
                <label class="form-label column">{{ `${value.length} / ${options.length} selected` }}</label>
                <label
                    v-if="can_select_none || can_select_all"
                    class="form-label column text-right"
                >
                    {{ $t("Select") }}:
                    <default-button
                        v-if="can_select_all"
                        flavor="link"
                        color="light"
                        size="sm"
                        @click.prevent="select_all_options"
                    >
                        {{ $t("All") }}
                    </default-button>
                    <span v-if="can_select_none && can_select_all" class="mx-2">|</span>
                    <default-button
                        v-if="can_select_none"
                        flavor="link"
                        color="light"
                        size="sm"
                        @click.prevent="select_no_options"
                    >
                        <span v-if="required && defaultSelection.length">{{ $t("Defaults") }}</span>
                        <span v-else>{{ $t("None") }}</span>
                    </default-button>
                </label>
            </div>
            <slot name="hint"></slot>
        </template>
        <ul>
            <li
                v-for="option in options"
                :key="option[idField]"
                class="columns"
            >
                <div class="column">
                    <default-toggle-input
                        :id="`${name}-option-${option[idField]}`"
                        :name="name"
                        type="checkbox"
                        :value="option_is_selected(option)"
                        :size="size"
                        :aria-describedby="!!has_slot('hint') ? `${name}-option-${option[idField]}-description` : false"
                        :disabled="!editable || disabled || (requiredSelection.indexOf(option[idField]) >= 0) || (required && option_is_selected(option) && (!!value && value.length <= 1))"
                        @input="option_toggle(option)"
                    >
                        {{ option[labelField] }}
                    </default-toggle-input>
                </div>
                <slot name="option" :selected="option_is_selected(option)" :option="option" :disabled="disabled"></slot>
            </li>
        </ul>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import DefaultToggleInput from "@/nibnut/components/Inputs/DefaultToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "FormOptionsPicker",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        DefaultToggleInput,
        DefaultButton
    },
    mounted () {
        this.update_display_value()
    },
    watch: {
        value: "update_display_value"
    },
    methods: {
        update_display_value () {
            this.display_value = ""
            if(this.value && this.value.length) {
                const labels = this.value.map(id => {
                    const option = this.options.find(option => option[this.idField] === id)
                    return option ? option[this.labelField] : id
                })
                this.display_value = labels.join(", ")
            }
        },
        option_is_selected (option) {
            return this.value.indexOf(option[this.idField]) >= 0
        },
        option_toggle (option) {
            const selection = [...this.value]
            const index = selection.indexOf(option[this.idField])
            if(index >= 0) selection.splice(index, 1)
            else selection.push(option[this.idField])
            this.$emit("input", selection, this.name)
        },
        select_all_options () {
            const selection = this.options.map(option => option[this.idField])
            this.$emit("input", selection, this.name)
        },
        select_no_options () {
            this.$emit("input", this.required ? [...this.defaultSelection] : [], this.name)
        }
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        can_select_all () {
            return !!this.value && (this.value.length < (this.options.length - 1))
        },
        can_select_none () {
            if(this.required && this.defaultSelection.length) {
                const selection = [...this.value].sort()
                const default_selection = [...this.defaultSelection].sort()
                return !!this.value && (selection.sort().join("|") !== default_selection.sort().join("|"))
            }
            return true
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // array of object.idField values
            type: Array,
            default () {
                return []
            }
        },
        defaultSelection: {
            type: Array,
            default () {
                return []
            }
        },
        requiredSelection: {
            type: Array,
            default () {
                return []
            }
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            display_value: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-options-picker {
    ul {
        margin: 0;
        max-height: 11rem;
        overflow: auto;
        border: $border-width dashed $border-color;

        & > li {
            margin: 0;
        }
    }
    .form-input-hint .btn.btn-sm {
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        line-height: normal;
        font-size: inherit;
    }
}
</style>
