<template>
    <div class="header app-header">
        <header class="navbar">
            <section class="navbar-section">
                <default-button
                    v-if="is_at_least_administrator"
                    flavor="link"
                    @click.prevent="$emit('navigate', null)"
                >
                    <app-icon glyph="menu" />
                </default-button>
                <default-button
                    v-if="!!profile_id||isPublicPage"
                    flavor="link"
                    class="show-sm"
                    @click.prevent="bug_report"
                >
                    <app-icon glyph="bug" />
                </default-button>
            </section>
            <section class="navbar-center">
                <base-link
                    :href="{ name: 'home' }"
                    @click.native="$emit('navigate', false)"
                >
                    <app-logo />
                </base-link>
            </section>
            <section class="navbar-section">
                <default-button
                    v-if="!!app_context && !!app_context.settings && app_context.settings.bug_reporter_active && (!!profile_id || isPublicPage)"
                    flavor="link"
                    class="mr-4 hide-sm"
                    @click.prevent="bug_report"
                >
                    <app-icon glyph="bug" size="lg" />
                </default-button>
                <default-button
                    v-if="!!profile_id"
                    flavor="link"
                    :title="$t('Logout')"
                    @click.prevent="logout"
                >
                    <app-icon glyph="log-out" size="lg" />
                </default-button>
                <default-button
                    v-else-if="!profile_id"
                    flavor="link"
                    :title="$t('Login/Signup')"
                    @click.prevent="login"
                >
                    <app-icon glyph="log-in" size="lg" />
                </default-button>
                <base-link
                    :href="{ name: 'profile' }"
                    class="btn btn-link"
                >
                    <figure class="avatar avatar-sm">
                        <app-icon v-if="!profile_id||(!!profile&&!profile.avatar)" glyph="user" />
                        <img v-else :src="profile.avatar" :alt="profile.name">
                    </figure>
                </base-link>
            </section>
        </header>
    </div>
</template>

<script>
import { profile_utilities } from "@/nibnut/mixins"

import { AppLogo, DefaultButton, BaseLink, AppIcon } from "@/nibnut/components"

export default {
    mixins: [profile_utilities],
    components: {
        AppLogo,
        DefaultButton,
        BaseLink,
        AppIcon
    },
    methods: {
        bug_report () {
            this.$emit("bug-report")
        },
        login () {
            this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
        },
        logout () {
            this.$store.dispatch("LOGOUT")
        }
    },
    props: {
        isPublicPage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.header {
    .navbar-section:first-child {
        padding-left: 0.3rem;
    }
    .btn {
        border: 0;

        i {
            font-size: 1rem;
        }
    }
}
</style>
