import handles_saving from "./HandlesSaving"
import profile_utilities from "./ProfileUtilities"
import crud_utilities from "./CrudUtilities"
import confirms from "./Confirms"

const is_in_situ_list_record_editor = {
    mixins: [handles_saving, profile_utilities, crud_utilities, confirms],
    methods: {
        save_row (row, value, field) {
            if(row) {
                if(row[field] !== value) row[field] = value
                if(row.id) return this.save_field_for_record_id(this.entity, row.id, value, field)
            }
            return Promise.resolve()
        },
        saving_row (row, field = "") {
            return this.saving_row(field, row.id)
        },
        define_new_row () {
            this.record_shell(this.entity).then(record => {
                this.shell_row = { ...record }
                this.defining_row = true
            })
        },
        create_new_row () {
            if(!this.shell_row || !!this.shell_row.id) return

            this.creating_row = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: this.shell_row
            }).then(() => {
                this.defining_row = false
                this.shell_row = {}
                if(this.refresh) this.refresh()
            }).catch(this.receive_error).then(() => {
                this.creating_row = false
            })
        },
        confirm_delete_row_strings (row) {
            return {
                title: this.$t("Delete row"),
                message: this.$t("Do you really want to delete this row? This operation can not be undone."),
                cancel: this.$t("Keep"),
                ok: this.$t("Delete")
            }
        },
        confirm_delete_row (row) {
            this.confirm(
                {
                    type: "error",
                    ...this.confirm_delete_row_strings(row),
                    row
                },
                "delete-record"
            )
        },
        deleting_row (row) {
            return this.deleting_rows.indexOf(row.id) >= 0
        },
        confirmed () {
            if(this.deleting_rows.indexOf(this.confirmation_props.row.id) < 0) this.deleting_rows.push(this.confirmation_props.row.id)
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: this.confirmation_props.row.id
                }
            ).then(() => {
                this.done_confirming()
            }).catch(this.receive_error).then(() => {
                const index = this.deleting_rows.indexOf(this.confirmation_props.row.id)
                if(index >= 0) this.deleting_rows.splice(index, 1)
            })
        }
    },
    computed: {
    },
    data () {
        return {
            defining_row: false,
            creating_row: false,
            shell_row: {},
            deleting_rows: []
        }
    }
}

export default is_in_situ_list_record_editor
