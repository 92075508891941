<template>
    <component
        :is="icon"
        :size="standardized_size"
        v-bind="svg_props"
        :class="`lucide-icon-${size}`"
    />
</template>

<script type="text/javascript">
export default {
    name: "AppIcon",
    mounted () {
        this.reset_import()
    },
    watch: {
        glyph: "reset_import"
    },
    methods: {
        reset_import () {
            if(this.glyph) this.icon_import = () => import(`lucide-vue/dist/esm/icons/${this.glyph}.js`)
        }
    },
    computed: {
        icon () {
            return this.icon_import
        },
        svg_props () {
            const { glyph, size, ...svg_props } = this.$props
            return svg_props
        },
        standardized_size () {
            if(typeof this.size === "number") return this.size
            if(this.size === "xs") return 10
            if(this.size === "sm") return 12
            if(this.size === "lg") return 24
            if(this.size === "2x") return 32
            if(this.size === "3x") return 48
            if(this.size === "4x") return 64
            if(this.size === "5x") return 80
            return 16
        }
    },
    props: {
        glyph: {
            type: String,
            required: true
        },
        size: {
            validator: prop => {
                return !prop || (typeof prop === "number") || !!prop.match(/^(xs|sm|lg|2x|3x|4x|5x)$/i)
            },
            default: ""
        },
        color: {
            type: String,
            default: "currentColor"
        },
        strokeWidth: {
            type: Number,
            default: 1
        }
    },
    data () {
        return {
            icon_import: null
        }
    }
}
</script>

<style lang="scss">
.lucide-icon.lucide-icon- {
    position: relative;
    top: 2px;
}
</style>
