import { mapState } from "vuex"

const ui_utilities = {
    methods: {
        scroll_to (target) {
            if(!target) return
            const scroll_margin = parseInt(getComputedStyle(target)["scroll-margin-top"]) || 0
            window.scrollTo({ top: target.getBoundingClientRect().y + window.scrollY - scroll_margin, left: 0, behavior: "smooth" })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        print_screen () {
            const mq = window.matchMedia("print")
            return !!mq && !!mq.matches
        },
        small_screen () {
            return !!this.$mq.match(/^(xs|sm|md|lg)$/i)
        },
        is_public_page () {
            return !this.$route.meta || (!this.$route.meta.login_required && !this.$route.meta.admin_route)
        }
    }
}

export default ui_utilities
