<template>
    <div
        :class="{ 'active': dragging, disabled }"
        class="nibnut-document-uploader nibnut-hoverable"
    >
        <div
            v-show="!disabled"
            ref="dazone"
            aria-hidden="true"
            @click.prevent="upload_pick_file"
            class="dnd-zone"
        ></div>
        <div
            v-if="!url"
            @click.prevent="upload_pick_file"
            class="empty"
            :style="{ 'height': dragging ? `${height}px` : false }"
        >
            <div class="empty-icon text-center hide-sm">
                <app-icon
                    v-if="!dragging && !!error"
                    glyph="alert-triangle"
                    size="3x"
                    :title="error"
                    class="text-error"
                />
                <app-icon
                    v-else
                    :glyph="glyph"
                    size="3x"
                />
            </div>
            <p v-if="!dragging && !!error" class="empty-title h6 text-error" v-html="error"></p>
            <p v-else-if="!!uploading" class="empty-title h6 hide-sm">{{ $t("Uploading...") }}</p>
            <p v-else-if="!!dragging" class="empty-title h6 hide-sm">
                <slot name="dragging-title">{{ $t("Drop your file to upload it") }}</slot>
            </p>
            <p v-else-if="!dragging" class="empty-title h6 hide-sm">
                <slot name="empty-title">{{ $t("Drag your file here, or use the button below") }}</slot>
            </p>
            <div v-if="!dragging" class="empty-action">
                <progress
                    v-if="uploading"
                    :value="uploaded"
                    max="100"
                    class="progress">
                </progress>
                <default-button
                    v-else
                    :disabled="disabled"
                    class="btn btn-primary btn-sm hide-sm"
                >
                    <slot name="empty-button-title">{{ $t("Select file...") }}</slot>
                </default-button>
            </div>
            <p
                v-if="has_slot('instructions')"
                class="empty-title text-small"
            >
                <slot name="instructions"></slot>
            </p>
        </div>
        <div
            v-else
            class="content-holder"
            :style="{ 'height': dragging ? `${height}px` : false }"
            @click.prevent="upload_pick_file"
        >
            <div class="content-display">
                <slot name="display">
                    <base-link
                        :href="url"
                    >
                        {{ url }}
                    </base-link>
                </slot>
            </div>
            <div
                v-if="!!error"
                class="error"
                @click.stop.prevent="hide_error"
            >
                <div v-html="error"></div>
            </div>
        </div>
        <default-button
            v-if="!!url && !dragging && !error && deletable && !disabled"
            class="s-circle nibnut-document-uploader-clear"
            @click.stop.prevent="clear_uploader"
        >
            <app-icon
                glyph="x-circle"
                :title="deleteButtonTitle || $t('Delete file...')"
            />
        </default-button>

        <input
            v-if="file_field_ready"
            :name="`${identifier}-proxy-file-input`"
            type="file"
            ref="file"
            :accept="accepts"
            :disabled="disabled"
            @change="upload_single_file"
            class="nibnut-proxied-control"
        />

        <default-button
            v-if="!!url && !dragging && !error && !disabled && (!!replaceable || !url)"
            color="primary"
            @click.prevent="upload_pick_file"
            class="s-circle nibnut-hover-visible">
            <app-icon
                :glyph="glyph"
                :title="replaceTitle || $t('Replace file...')"
                size="lg"
                class="form-icon"
            />
        </default-button>
    </div>
</template>

<script type="text/javascript">
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import AppIcon from "@/nibnut/components/AppIcon"

const can_drag_and_drop = () => {
    const div = document.createElement("div")
    return (("draggable" in div) || (("ondragstart" in div) && ("ondrop" in div))) && ("FormData" in window) && ("FileReader" in window)
}
const event_prevent_and_stop = event => {
    event.preventDefault()
    event.stopPropagation()
}

export default {
    mixins: [is_nibnut_component],
    components: {
        DefaultButton,
        BaseLink,
        AppIcon
    },
    mounted () {
        // inspired by https://serversideup.net/drag-and-drop-file-uploads-with-vuejs-and-axios/
        if(can_drag_and_drop()) {
            ["drag", "dragstart", "dragend", "dragover"].forEach(event_name => {
                this.$refs.dazone.addEventListener(event_name, event_prevent_and_stop)
            })

            this.$refs.dazone.addEventListener("dragenter", this.drag_enter_handler)
            this.$refs.dazone.addEventListener("dragleave", this.drag_leave_handler)
            this.$refs.dazone.addEventListener("drop", this.drop_handler)
        }
    },
    beforeDestroy () {
        if(can_drag_and_drop()) {
            ["drag", "dragstart", "dragend", "dragover"].forEach(event_name => {
                this.$refs.dazone.removeEventListener(event_name, event_prevent_and_stop)
            })

            this.$refs.dazone.removeEventListener("dragenter", this.drag_enter_handler)
            this.$refs.dazone.removeEventListener("dragleave", this.drag_leave_handler)
            this.$refs.dazone.removeEventListener("drop", this.drop_handler)
        }
    },
    methods: {
        drag_enter_handler (event) {
            event_prevent_and_stop(event)
            if(this.disabled || (!this.replaceable && !!this.url)) return
            this.height = this.$refs.dazone.offsetHeight
            this.dragging = true
        },
        drag_leave_handler (event) {
            event_prevent_and_stop(event)
            this.dragging = false
        },
        drop_handler (event) {
            event_prevent_and_stop(event)
            if(this.disabled || (!this.replaceable && !!this.url)) return
            this.upload(event.dataTransfer.files)
            this.dragging = false
        },
        upload_pick_file () {
            if(this.disabled || (!this.replaceable && !!this.url)) return
            this.$refs.file.click()
        },
        upload_single_file () {
            if(this.disabled || (!this.replaceable && !!this.url)) return
            this.upload(this.$refs.file.files)
            this.file_field_ready = false
            this.$nextTick(() => {
                this.file_field_ready = true
            })
        },
        upload (file_list) {
            if(this.disabled || (!this.replaceable && !!this.url) || !file_list || !file_list.length) return
            this.$emit("upload", file_list)
        },
        clear_uploader () {
            if(this.disabled || !this.deletable) return
            this.$emit("clear")
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        error: {
            type: String,
            default: ""
        },
        uploading: {
            type: Boolean,
            default: false
        },
        uploaded: {
            type: Number,
            default: 0
        },
        replaceable: {
            type: Boolean,
            default: true
        },
        deletable: {
            type: Boolean,
            default: true
        },
        accepts: { // mime types
            type: String,
            default: ""
        },
        glyph: {
            type: String,
            default: "upload-cloud"
        },
        deleteButtonTitle: {
            type: String,
            default: ""
        },
        replaceTitle: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            dragging: false,
            file_field_ready: true,
            height: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-document-uploader {
    position: relative;
    height: 100%;

    .dnd-zone {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zindex-3;
        cursor: pointer;
    }
    &, .empty, .error, .content-holder {
        width: 100%;
        height: 100%;
    }
    .btn.s-circle.nibnut-hover-visible {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.8rem;
        height: 3.6rem;
        width: 3.6rem;
        line-height: 1.8rem;
    }
    .nibnut-document-uploader-clear {
        position: absolute;
        bottom: $layout-spacing-sm;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: $zindex-3;
    }
    progress {
        max-width: 90%;
    }

    & > .empty {
        border: $control-padding-x dashed $bg-color-dark;
        padding: $unit-8;
        max-height: 100%;

        & > .empty-icon {
            position: relative;
            height: 2rem;
        }
    }
    & > .content-holder {
        position: relative;

        progress {
            position: absolute;
            left: 50%;
            bottom: $control-padding-y;
            max-width: 90%;
            transform: translate(-50%, 0);
        }
        .content-display {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            & > img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .error {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($primary-color, 0.9);
            color: white;
            font-size: 1.5rem;
            z-index: $zindex-2;

            & > div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
    }

    &.active {
        & > .empty {
            background-color: $bg-color-dark;
            border-color: $primary-color;
        }
        & > .content-holder {
            background-color: $primary-color;
            & > .content-display {
                opacity: 0.3;
            }
        }
    }

    &:not(.disabled):hover {
        & > .content-holder .content-display {
            opacity: 0.2;
        }
    }
}
@media(max-width:$size-sm) {
    .nibnut-document-uploader {
        &, .empty, .error, .content-holder {
            width: 100%;
            height: auto;
        }
        & > .empty {
            padding-top: 0;
            padding-bottom: 0;

            & > .empty-icon {
                height: auto;
                margin-bottom: 0;
            }
        }
    }
}
</style>
