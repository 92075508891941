import { mapState } from "vuex"

const misc_utilities = {
    methods: {
        setting (id) {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining#browser_compatibility
            if(this.app_context && this.app_context.settings) return this.app_context.settings[id]
            return null
        },
        constants (group, id = null) {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining#browser_compatibility
            if(!!this.app_context && !!this.app_context.constants && !!this.app_context.constants[group]) {
                if(!id) return this.app_context.constants[group]
                return this.app_context.constants[group][id] || {}
            }
            return {}
        },
        constant_options (group) {
            return Object.values(this.constants(group))
        }
    },
    computed: {
        ...mapState(["app_context"])
    }
}

export default misc_utilities
