const string_utilities = {
    methods: {
        coupon_terms (coupon) {
            const terms = []
            if(coupon) {
                if(coupon.amount_off) terms.push(this.nibnut_filter("nibnut.currency", [coupon.amount_off / 100]))
                else terms.push(`${this.nibnut_filter("nibnut.number", [coupon.percent_off / 100, "0,0.00"])}%`)
            }
            terms.push(this.$t("off"))
            switch (coupon.duration) {
            case "once":
                terms.push(this.$t("once"))
                break
            case "forever":
                terms.push(this.$t("forever"))
                break
            default:
                terms.push(this.$tc("1 month | {count} months", coupon.duration_in_months, { count: coupon.duration_in_months }))
                break
            }
            return terms.join(" ")
        },
        string_concat () {
            // (separator, component1, component2, ...)
            if(arguments.length < 2) return ""
            const separator = arguments[0]
            const components = Array.from(arguments).slice(1).flat()
            return components.filter(component => !!component).join(separator)
        },
        address_block_line (line, address) {
            if(line === 1) {
                return address.line1
            } else if(line === 2) {
                return this.string_concat(", ", address.city, this.string_concat(" ", address.state, address.zip))
            }
            return ""
        },
        address_one_line (address) {
            return this.string_concat(", ", this.address_block_line(1, address), this.address_block_line(2, address))
        },
        full_name (record) {
            return this.string_concat(" ", record.first_name, record.last_name)
        }
    },
    computed: {
        app_version () {
            // eslint-disable-next-line
            if(process.env.VUE_APP_VERSION) return process.env.VUE_APP_VERSION
            return ""
        },
        current_year () {
            return this.$dayjs().format("YYYY")
        }
    }
}

export default string_utilities
