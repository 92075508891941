<template>
    <div
        class="form-autocomplete nibnut-token-input"
    >
        <div class="form-autocomplete-input form-input">
            <slot name="selection">
                <div
                    v-for="token in value"
                    :key="token"
                    class="chip"
                >
                    {{ token }}
                    <a
                        href
                        class="btn btn-clear"
                        aria-label="Close"
                        role="button"
                        @click.prevent="delete_token(token)"
                    ></a>
                </div>
            </slot>

            <base-input
                ref="field"
                :id="id"
                type="text"
                :name="name"
                :value="query"
                :disabled="disabled"
                :required="required"
                @touchstart="touchstart"
                @keydown="keydown"
                @change="changed"
                @blur="blur"
            />
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import BaseInput from "./BaseInput"

export default {
    name: "BaseTokenInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        BaseInput
    },
    methods: {
        keydown (event) {
            if(this.alphanumeric && this.iOS) this.touching = false
            // " " or "," => add current query and reset
            switch (event.key) {
            case " ":
            case ",":
            case "Enter":
                if(this.query && (!this.tokenValidator || this.tokenValidator(this.query))) {
                    this.query = event.target.value
                    this.add_token()
                }
                event.preventDefault()
                break

            case "Escape":
                this.query = ""
                event.target.value = ""
                event.target.blur()
                event.stopPropagation()
                event.preventDefault()
                break

            case "Backspace":
                if(!event.target.value && !!this.value.length) {
                    this.delete_token(this.value.at(-1))
                    this.query = ""
                } else this.query = event.target.value
                break

            default:
                this.query = event.target.value
                break
            }
        },
        changed (event) {
            this.query = event.target.value
        },
        add_token () {
            if(this.query && (!this.tokenValidator || this.tokenValidator(this.query))) {
                this.$emit("input", this.query)
                this.query = ""
            }
        },
        delete_token (token) {
            this.$emit("delete", token)
        },
        blur (event) {
            if(this.alphanumeric && this.iOS) this.touching = false
            this.add_token()
            this.query = ""
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // array of tokens
            type: Array,
            default () {
                return []
            }
        },
        tokenValidator: {
            default: null
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            query: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-autocomplete.nibnut-token-input {
    .form-autocomplete-input {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}
</style>
